






























































































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    }),
    communityImages(): string[] {
      return [
        this.oss.cyberblade_swiper_01_01,
        this.oss.cyberblade_swiper_01_02,
        this.oss.cyberblade_swiper_01_03,
        this.oss.cyberblade_swiper_01_04,
        this.oss.cyberblade_swiper_01_05,
        this.oss.cyberblade_swiper_01_06,
        this.oss.cyberblade_swiper_01_07,
        this.oss.cyberblade_swiper_01_08,
        this.oss.cyberblade_swiper_01_09,
        this.oss.cyberblade_swiper_01_10,
        this.oss.cyberblade_swiper_01_11,
        this.oss.cyberblade_swiper_01_12,
        this.oss.cyberblade_swiper_01_13,
        this.oss.cyberblade_swiper_01_14,
        this.oss.cyberblade_swiper_02_01,
        this.oss.cyberblade_swiper_02_02,
        this.oss.cyberblade_swiper_02_03,
        this.oss.cyberblade_swiper_02_04,
        this.oss.cyberblade_swiper_02_05,
        this.oss.cyberblade_swiper_02_06,
        this.oss.cyberblade_swiper_02_07,
        this.oss.cyberblade_swiper_02_08,
        this.oss.cyberblade_swiper_02_09,
        this.oss.cyberblade_swiper_02_10,
        this.oss.cyberblade_swiper_02_11,
        this.oss.cyberblade_swiper_02_12,
        this.oss.cyberblade_swiper_02_13,
        this.oss.cyberblade_swiper_02_14,
        this.oss.cyberblade_swiper_02_15,
        this.oss.cyberblade_swiper_02_16,
        this.oss.cyberblade_swiper_02_17,
        this.oss.cyberblade_swiper_02_18,
        this.oss.cyberblade_swiper_02_19,
        this.oss.cyberblade_swiper_02_20,
        this.oss.cyberblade_swiper_02_21,
        this.oss.cyberblade_swiper_02_22,
        this.oss.cyberblade_swiper_02_23,
        this.oss.cyberblade_swiper_02_24,
        this.oss.cyberblade_swiper_02_25
      ];
    },
    communityNames(): string[] {
      return this.isCN
        ? [
            "by 青空",
            "by 青空",
            "by 青空",
            "by 青空",
            "by 青空",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by 夹克2",
            "by 夹克2",
            "by 夹克2",
            "by 夹克2",
            "by ZZY",
            "by ZZY",
            "by Shipike",
            "by Shipike",
            "by Shipike",
            "by KK",
            "by KK",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 沙拉",
            "by 鬼鬼",
            "by 鬼鬼",
            "by 肌肉肥羊",
            "by 肌肉肥羊",
            "by 长安"
          ]
        : [
            "by Qingkong",
            "by Qingkong",
            "by Qingkong",
            "by Qingkong",
            "by Qingkong",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by Jiake2",
            "by Jiake2",
            "by Jiake2",
            "by Jiake2",
            "by ZZY",
            "by ZZY",
            "by Shipike",
            "by Shipike",
            "by Shipike",
            "by KK",
            "by KK",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Shala",
            "by Ghost",
            "by Ghost",
            "by Jirou Feiyang",
            "by Jirou Feiyang",
            "by Changan"
          ];
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_cyberboard_r4);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberboard/", "_blank");
    }
  }
});
