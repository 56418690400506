import { render, staticRenderFns } from "./CyberboardR4.vue?vue&type=template&id=1b776908&scoped=true&"
import script from "./CyberboardR4.vue?vue&type=script&lang=ts&"
export * from "./CyberboardR4.vue?vue&type=script&lang=ts&"
import style0 from "./CyberboardR4.vue?vue&type=style&index=0&id=1b776908&lang=scss&scoped=true&"
import style1 from "./CyberboardR4.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b776908",
  null
  
)

export default component.exports